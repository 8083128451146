import React, { ReactNode } from "react"
import { Container } from "../../UI/Container"

export const Title: React.FC<{
  title: ReactNode
}> = (props) => {
  return (
    <Container className="mx-auto py-10">
      <div className="mx-auto flex max-w-3xl justify-start text-pink-400/20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill="none"
          className="relative xl:-left-10"
        >
          <path
            d="M6.18204 20C4.5789 20.0002 3.03989 19.3989 1.89687 18.3258C0.753851 17.2526 0.0983906 15.7936 0.0718231 14.2633C-0.0852966 12.8217 -0.0626011 10.695 0.958677 8.24C2.00265 5.73 4.03823 2.99334 7.75324 0.333336C7.93664 0.202015 8.14535 0.106464 8.36744 0.052145C8.58952 -0.00217438 8.82064 -0.0142021 9.04759 0.0167522C9.50595 0.0792637 9.91952 0.313046 10.1973 0.666668C10.4751 1.02029 10.5944 1.46479 10.5289 1.90237C10.4635 2.33996 10.2186 2.73478 9.84817 3C7.11778 4.955 5.55356 6.855 4.65798 8.51667C5.49763 8.31081 6.37355 8.27788 7.22756 8.42007C8.08156 8.56226 8.8941 8.87631 9.6112 9.34135C10.3283 9.8064 10.9335 10.4118 11.3867 11.1173C11.8399 11.8229 12.1306 12.6124 12.2396 13.4334C12.3485 14.2545 12.2732 15.0883 12.0187 15.8794C11.7642 16.6705 11.3362 17.4009 10.7633 18.022C10.1903 18.6431 9.48553 19.1407 8.69566 19.4817C7.90578 19.8228 7.04893 19.9994 6.18204 20ZM21.894 20C20.2909 20.0002 18.7519 19.3989 17.6089 18.3258C16.4658 17.2526 15.8104 15.7936 15.7838 14.2633C15.6267 12.8217 15.6494 10.695 16.6707 8.24C17.7129 5.73 19.7502 2.99334 23.4652 0.333336C23.6486 0.202015 23.8573 0.106464 24.0794 0.052145C24.3015 -0.00217438 24.5326 -0.0142021 24.7596 0.0167522C25.2179 0.0792637 25.6315 0.313046 25.9093 0.666668C26.1871 1.02029 26.3064 1.46479 26.2409 1.90237C26.1754 2.33996 25.9306 2.73478 25.5602 3C22.8298 4.955 21.2655 6.855 20.37 8.51667C21.2096 8.31081 22.0855 8.27788 22.9395 8.42007C23.7935 8.56226 24.6061 8.87631 25.3232 9.34135C26.0403 9.8064 26.6455 10.4118 27.0987 11.1173C27.5518 11.8229 27.8426 12.6124 27.9515 13.4334C28.0605 14.2545 27.9852 15.0883 27.7307 15.8794C27.4762 16.6705 27.0482 17.4009 26.4753 18.022C25.9023 18.6431 25.1975 19.1407 24.4076 19.4817C23.6178 19.8228 22.7609 19.9994 21.894 20Z"
            fill="currentColor"
          />
        </svg>
      </div>

      <div className="relative mx-auto flex max-w-3xl flex-col items-center justify-center text-center">
        <h2 className="font-display text-3xl font-semibold leading-tight tracking-tight text-slate-900">
          {props.title}
        </h2>
      </div>

      <div className="mx-auto flex max-w-3xl justify-end text-pink-400/20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill="none"
          className="relative scale-x-[-1] scale-y-[-1] xl:-right-10"
        >
          <path
            d="M6.18204 20C4.5789 20.0002 3.03989 19.3989 1.89687 18.3258C0.753851 17.2526 0.0983906 15.7936 0.0718231 14.2633C-0.0852966 12.8217 -0.0626011 10.695 0.958677 8.24C2.00265 5.73 4.03823 2.99334 7.75324 0.333336C7.93664 0.202015 8.14535 0.106464 8.36744 0.052145C8.58952 -0.00217438 8.82064 -0.0142021 9.04759 0.0167522C9.50595 0.0792637 9.91952 0.313046 10.1973 0.666668C10.4751 1.02029 10.5944 1.46479 10.5289 1.90237C10.4635 2.33996 10.2186 2.73478 9.84817 3C7.11778 4.955 5.55356 6.855 4.65798 8.51667C5.49763 8.31081 6.37355 8.27788 7.22756 8.42007C8.08156 8.56226 8.8941 8.87631 9.6112 9.34135C10.3283 9.8064 10.9335 10.4118 11.3867 11.1173C11.8399 11.8229 12.1306 12.6124 12.2396 13.4334C12.3485 14.2545 12.2732 15.0883 12.0187 15.8794C11.7642 16.6705 11.3362 17.4009 10.7633 18.022C10.1903 18.6431 9.48553 19.1407 8.69566 19.4817C7.90578 19.8228 7.04893 19.9994 6.18204 20ZM21.894 20C20.2909 20.0002 18.7519 19.3989 17.6089 18.3258C16.4658 17.2526 15.8104 15.7936 15.7838 14.2633C15.6267 12.8217 15.6494 10.695 16.6707 8.24C17.7129 5.73 19.7502 2.99334 23.4652 0.333336C23.6486 0.202015 23.8573 0.106464 24.0794 0.052145C24.3015 -0.00217438 24.5326 -0.0142021 24.7596 0.0167522C25.2179 0.0792637 25.6315 0.313046 25.9093 0.666668C26.1871 1.02029 26.3064 1.46479 26.2409 1.90237C26.1754 2.33996 25.9306 2.73478 25.5602 3C22.8298 4.955 21.2655 6.855 20.37 8.51667C21.2096 8.31081 22.0855 8.27788 22.9395 8.42007C23.7935 8.56226 24.6061 8.87631 25.3232 9.34135C26.0403 9.8064 26.6455 10.4118 27.0987 11.1173C27.5518 11.8229 27.8426 12.6124 27.9515 13.4334C28.0605 14.2545 27.9852 15.0883 27.7307 15.8794C27.4762 16.6705 27.0482 17.4009 26.4753 18.022C25.9023 18.6431 25.1975 19.1407 24.4076 19.4817C23.6178 19.8228 22.7609 19.9994 21.894 20Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </Container>
  )
}
